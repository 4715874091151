.wrapperft {
  min-height: 100vh;
  margin-bottom: -50px;
}


.push {
  height: 50px;
}

.full_blue {
  background-color : #183996;
}